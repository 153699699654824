import Button from '@mui/material/Button'
import { storyblokEditable } from '@storyblok/react'
import cn from '@utils/helpers/cn'
import localRoute from '@utils/helpers/localRoute'
import Link from 'next/link'

const MuiButton = ({ blok }) => {
  return (
    <Button
      variant={blok.variant}
      color={blok.color}
      href={blok.locale ? localRoute(blok.href.url) : blok.href.url}
      target={blok.href.target}
      size={blok.size}
      disabled={blok.disabled}
      fullWidth={blok.fullWidth}
      className={cn(blok.class)}
      component={blok.locale ? Link : 'button'}
      {...storyblokEditable(blok)}
    >
      {blok.text}
    </Button>
  )
}

export default MuiButton
